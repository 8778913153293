<template>
    <DropdownList
        :show="isOpen"
        :label="$t('Sorting')"
        :selected="selectedOption.value"
        :options="options"
        class="sort"
        @select="setOption($event)"
        @close="$emit('close')"
    />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';

import { DropdownList } from '@eobuwie-ui/components/DropdownList/v2';

const {
    mapState: mapSortState,
    mapActions: mapSortActions,
} = createNamespacedHelpers('sort');
const { mapActions: mapLayoutActions } = createNamespacedHelpers('layout');

const SORT_VALUE_ORDER_SEPARATOR = '-';

export default {
    name: 'Sort',

    components: {
        DropdownList,
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },

        lockBodyScroll: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close', 'apply-sort'],

    computed: {
        ...mapSortState([
            'defaultOrderCode',
            'orderOptions',
            'selectedSortOption',
        ]),

        defaultOption() {
            return this.transformSortOptionToSelectOption({
                code: '',
                order: '',
                label: this.$t('Default'),
            });
        },

        options() {
            const options = this.orderOptions.map(
                this.transformSortOptionToSelectOption
            );

            return [this.defaultOption, ...options];
        },

        selectedOption() {
            const {
                code: selectedCode,
                order: selectedOrder,
            } = this.selectedSortOption;

            return (
                this.options.find(
                    ({ code, order }) =>
                        code === selectedCode && order === selectedOrder
                ) || this.defaultOption
            );
        },
    },

    watch: {
        async isOpen(open) {
            await this.$nextTick();

            if (open) {
                this.applyLockBodyScroll();

                return;
            }

            this.removeLockBodyScroll();
        },
    },

    async mounted() {
        await this.$nextTick();

        if (this.isOpen) {
            this.applyLockBodyScroll();
        }
    },

    destroyed() {
        this.removeLockBodyScroll();
    },

    methods: {
        ...mapSortActions(['setSelectedSort']),
        ...mapLayoutActions(['addHTMLClasses', 'removeHTMLClasses']),

        transformSortOptionToSelectOption(option) {
            const { code: value = '', order = '', label } = option;

            return {
                value: `${value}${SORT_VALUE_ORDER_SEPARATOR}${order}`,
                code: value,
                order,
                label,
            };
        },

        setOption(value) {
            if (value !== this.selectedOption.value) {
                const [code, order] = value.split(SORT_VALUE_ORDER_SEPARATOR);

                this.setSelectedSort({ code, order });
            }

            if (!this.isMobile || this.isWithRadioButtons) {
                this.$emit('apply-sort');
            }
        },

        applyLockBodyScroll() {
            if (!this.lockBodyScroll) {
                return;
            }

            this.addHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },

        removeLockBodyScroll() {
            if (!this.lockBodyScroll) {
                return;
            }

            this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },
    },
};
</script>

<style lang="scss" scoped>
.sort {
    @apply relative z-5;

    :deep(.content) {
        @apply w-full;
    }
}
</style>
