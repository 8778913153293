<template>
    <button
        class="dropdown-filter-component"
        :class="{ selected: isSelected }"
        @click="$emit('click')"
    >
        {{ label }}
        <CircleDigit v-if="digit" :digit="digit" class="indicator" />

        <span v-if="isDropdown" class="icons">
            <Icon :width="24" :height="24" :icon="toggleIcon" />
        </span>
    </button>
</template>

<script>
import { ChevronUp, ChevronDown } from '@eobuwie-ui/icons/v2/navigation';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { CircleDigit } from '@eobuwie-ui/components/CircleDigit/v1';

export default {
    name: 'DropdownFilterComponent',

    components: {
        Icon,
        CircleDigit,
    },

    props: {
        label: {
            type: String,
            required: true,
        },

        digit: {
            type: Number,
            default: null,
        },

        isDropdown: {
            type: Boolean,
            default: false,
        },

        isOpen: {
            type: Boolean,
            default: false,
        },

        isSelected: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click'],

    setup() {
        return {
            ChevronUp,
            ChevronDown,
        };
    },

    computed: {
        toggleIcon() {
            return this.isOpen ? this.ChevronUp : this.ChevronDown;
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-filter-component {
    @apply flex flex-1 items-center justify-center;
    @apply h-ui-11 gap-ui-2 px-ui-4;
    @apply border-solid border-ui-1 border-ui-container-skeleton-default;
    @apply text-ui-text-primary-default;

    &.selected {
        @apply border-ui-container-primary-default;
    }
}
</style>
