<template>
    <WithClickOutsideDetection :handler="isOpen ? 'close' : null">
        <div class="sort-wrapper">
            <DropdownFilterComponent
                :label="dropdownButtonLabel"
                :is-dropdown="true"
                :is-open="isOpen"
                @click="toggle()"
            />

            <Sort
                :is-open="isOpen"
                class="sort"
                @close="close()"
                @apply-sort="onSortApply()"
            />
        </div>
    </WithClickOutsideDetection>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { SORT_MODAL } from '@search/configs/modals';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';
import Sort from '@molecules/Sort/Sort';

import DropdownFilterComponent from '@search-molecules/DropdownFilterComponent/DropdownFilterComponent';

const { mapState: mapSearchSortState } = createNamespacedHelpers('sort');

export default {
    name: 'SortDropdown',

    components: {
        WithClickOutsideDetection,
        DropdownFilterComponent,
        Sort,
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        ...mapSearchSortState(['selectedSortOption', 'orderOptions']),

        dropdownButtonLabel() {
            const {
                code: selectedCode,
                order: selectedOrder,
            } = this.selectedSortOption;

            const selectedOption = this.orderOptions.find(
                ({ code, order }) =>
                    code === selectedCode && order === selectedOrder
            );

            return selectedOption?.label ?? this.$t('Sort by');
        },
    },

    methods: {
        onSortApply() {
            this.$emit('apply-sort');
            this.close();
        },

        close() {
            this.isOpen = false;
        },

        toggle() {
            if (this.isOpen) {
                this.close();
                this.$modals.close(SORT_MODAL);

                return;
            }

            this.$modals.open(SORT_MODAL);
            this.isOpen = true;
        },
    },
};
</script>

<style scoped lang="scss">
$sort-dropdown-width: 375px;
$dropdown-item-height: 56px;

.sort-wrapper {
    @apply relative;

    .sort {
        @apply absolute top-full left-ui-0 mt-ui-1;
        width: $sort-dropdown-width;

        :deep(.cell-list-container) {
            height: $dropdown-item-height;
        }
    }
}
</style>
